import React, { FC } from 'react';
import { css, Global } from '@emotion/react'
import logo from '../../images/vvip/imgAzarVvipGroup.png';
import bg from '../../images/vvip/bg.svg';

// then 


const VVIPWelcomePage: FC = () => {
  
  return (
    <>
      <Global
       styles={css`
          body {
            direction: rtl;
            padding: 0;
            margin: 0;
            background-color: black;
            margin: 48px;
            background-image: url('${bg}');
            background-size: cover;
          }
       `}
      />
      {/* container  */}
      <div
        css={css`
          max-width: 400px;
          margin: auto;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <figure
            css={css`
              margin: 0;
              padding: 0;
            `}
          >
            <img
              src={logo}
              alt='you are invited vvip'
              css={css`
                width: 100%;
              `}
            />
            <figcaption
              css={css`
                display: block;
                width: 100%;
                margin-top: 18px;
                font-size: 12px; 
                text-align: center;
                color: #E5AE4C;
              `}
            >
              لقد تم اختيارك لعضوية أزار VVIP
            </figcaption>
          </figure>
        </div>

        <h1
          css={css`
            font-weight: bold;
            font-size: 30px;
            line-height: 34px;
            text-align: center;
            color: #E5AE4C;
            margin-top: 114px;
            margin-bottom: 130px;
          `}
        >
          شكرا لانضمامك لمجموعة ازارVVIP
        </h1>
        
        <footer
          css={css`
            padding-top: 32px;
          `}
        >
          <p
            css={css`
              text-align: center;
              font-size: 10px;
              color: #FFFFFF;
            `}
          >
            قد تخضع مزايا عضوية أزار VVIP للتغير من قِبل Hyperconnect Inc
          </p>
        </footer>
      </div>
    </>
  );
}

export default VVIPWelcomePage;